import { VStack, SimpleGrid, Text } from "@chakra-ui/react";
import Link from "next/link";
import { getRealLink } from "../../../utils/misc";

const FooterLinks = (props:{globalState:GlobalState}):JSX.Element => {

  return (
    <SimpleGrid templateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }} spacing={8}>
      {
        props.globalState.footerLinks.footerLinks
          .sort((a, b) => a.linkText.trim().localeCompare(b.linkText.trim()))
          .map((link: FooterLinkItem, index) => {
            const { cachedUrl, url } = link.link;
            
            const [linkHref] = getRealLink({ cached_url: cachedUrl, url: url }, false);

            return (
              <VStack
                key={index}
                
              >
                <Link href={linkHref} key={index} passHref  target={linkHref.indexOf('http') == 0 ? '_blank' : '_self'}>
                  <Text 
                    as="a" 
                    textAlign={"left"} 
                    fontWeight="bold" 
                    w="full"
                    target={linkHref.indexOf('http') == 0 ? '_blank' : '_self'}
                  >
                    {link.linkText}
                  </Text>
                </Link>
                <Text as="p" color="brand.darkgray.200">
                  {link.description}
                </Text>
              </VStack>
            )
          })
      }
    </SimpleGrid>
  )
}

export default FooterLinks;